import React from 'react';
import ship3 from"./asset/ship3.gif";
import ship2 from"./asset/ship2.gif";
import ship4 from"./asset/ship4.gif";
import render6 from "./asset/render6.gif";
import Footer from "./Footer";
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
function displayMenu(){
  const temp = document.getElementById("mi");
  temp.style.display = "block";
  document.getElementById("hamMenu2").style.display="block";
  document.getElementById("hamMenu").style.display="none";
 }
 function hideMenu(){
   const temp = document.getElementById("mi");
   temp.style.display = "none";
   document.getElementById("hamMenu2").style.display="none";
   document.getElementById("hamMenu").style.display="block";

  }
window.addEventListener("scroll", reveal);
function Gallery(){
  return (
    <>
    <body style={{backgroundImage: {render6} }}>
          <div id="top">
      <ul class = "nav">
        <li><a id ="href" href="/">Sol Pilot .io</a></li>
        <li><a id ="href" href="#footer">Contact</a></li>
      </ul>
      <button id="hamMenu" onClick={()=>displayMenu()}>
        <div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
      <button id="hamMenu2" onClick={()=>hideMenu()}><div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>

      <ul id ="mi">
          <li><a id ="tab" href="/">Home</a></li>         
          <li><a id ="tab" href="/lore">Lore</a></li> 
          <li><a id ="tab" href="/whitepaper">White Paper</a></li>
		  <li> <a  id = "signUp" href="/register">Register</a></li>
      </ul>
    </div>
  <br></br><br/>
  <h1 id ="rT">Development Gallery</h1>
  <br></br><br></br><br></br><br></br>
    <h1 id = "rSub">Pilot Art Line Work -Admiral Zavorfang</h1>
    <img id ="centerImg" src = "https://cdn.discordapp.com/attachments/937972207244697641/946886430267932732/pilot.JPG"></img>
    <br></br><br></br><br></br><br></br>
    <h1 id = "rSub">Ship #2 GIF</h1>
    <img id ="centerImg" src = {ship2}></img>
    <br></br><br></br><br></br><br></br>
    <h1 id = "rSub">Ship #3 GIF</h1>
    <img id ="centerImg" src = {ship3}></img>
    <br></br><br></br><br></br><br></br>
    <h1 id = "rSub">Ship #4 GIF</h1>
    <img id ="centerImg" src = {ship4}></img>
    <br></br><br></br><br></br><br></br>
    <h1 id = "rSub">Ship #4, Render #2 GIF</h1>
    <img id ="centerImg" src = {render6}></img>
    <br></br><br></br><br></br><br></br>
	<Footer></Footer>
    </body>
  </>)};
  export default Gallery;