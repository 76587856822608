import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Gallery from './Gallery';
import PilotTool from './PilotTool';
import Register from './Register';
import WhitePaper from './WhitePaper';
import Lore from './Lore';
import "./css/styles.css"
const Main = () => (
  <>
  <Routes>
    <Route  exact path='/' element={<Home/>}></Route>
    <Route path='/gallery' element={<Gallery/>}></Route>
    <Route path='/CreatePilot' element={<PilotTool/>}></Route>
	<Route path='/register' element ={<Register/>}></Route>	
  <Route path='/whitepaper' element ={<WhitePaper/>}></Route>	
  <Route path='/lore' element ={<Lore/>}></Route>	
  <Route path='/game' element={<iframe src="/game/index.html" style={{ width: "100%", height: "100vh", border: "none" }}></iframe>}></Route>
  </Routes>
  </>
);
export default Main;	