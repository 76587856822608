import React, {useState, useRef} from 'react';
import Footer from "./Footer";
import test from "./asset/test.jpg";
import { HexColorPicker, HexColorInput } from "react-colorful";
import {Canvas} from './Canvas';
import {CanvasProvider} from './CanvasContext';

  export default function Color() {
    const [color, setColor] = useState("#e600e6");
	const [color2, setColor2] = useState("#e600e6");
	const [color3, setColor3] = useState("#e600e6");
	const [color4, setColor4] = useState("#e600e6");
	const [color5, setColor5] = useState("#e600e6");
	const [color6, setColor6] = useState("#e600e6");
	var canvas = document.getElementById('canvas'); 
	const canvasRef = useRef(null);
	const backgroundColoring = () => {
	var context = canvas.getContext("2d");  
	var image = new Image();
	image.src = {test};
    context.beginPath();  
    // draw a blue rectangle 
    context.fillStyle = {color};  
    context.fillRect( 10, 10, 150, 100 );  
	context.drawImage(image,0,0);
    // draw a red rectangle 
    context.fillStyle = {color2}  
    context.fillRect( 60, 50, 150, 100 ); 
	}
	
  return (
    <>
    <link rel="stylesheet"  href="src\syles.css"></link>
      <div id="top">
        <ul class = "nav">
          <li><a href="/">Sol Pilot.io</a></li>
		  <li><a href="#footer">Contact</a></li>
          <li><a href="/gallery">Gallery</a></li>
        </ul>
      </div>
    <div id='stars2'></div>
    <div id='stars3'></div>
	<br/><br/><br/>
	<div id = 'row'>
		<div id ="devCaution"></div>
		<div id ="devCaution2"></div>
		<div id ="devCaution3"></div>
		<div id ="devCaution4"></div>
		<div><marquee id = "scream">Pilot Creation Tool is undergoing development. Page not functional. Check the<a id= "spec" href="https://discord.gg/SPBxVqdAsT"  target="_blank"> Discord</a> for updates. Follow on <a id= "spec" href="https://instagram.com/solpilot.io"  target="_blank">Instagram.</a> Check the <a id= "spec" href="https://twitter.com/SolPilot1"  target="_blank">Twitter.</a></marquee></div>
	</div>
    <h1 id ="rT">Create your Pilot Avatar</h1>
    <br></br>
    <h3 id ="cpSub">You must have the Phantom add-on to use this application. 👉<a id ="cpSub" href ="https://phantom.app/" target="_blank" rel="noopener">Add-On Here</a>👈</h3>
    <br></br>
	<CanvasProvider>
  <Canvas/>
	</CanvasProvider>

    <section>		
        <div id ="rT">
        <nav>
      <ul class="menu">
        <li id ="color">
            <button>Helmet
			<ul class="sub-menu1" style={{backgroundColor: color }}>
            <li><div><HexColorPicker id ="hex" color={color} onChange={setColor}></HexColorPicker><HexColorInput id="colorIn" placeholder= {color}></HexColorInput></div></li>
			</ul></button>
            </li>
            <li>
            <button>Chestpiece
            <ul class="sub-menu1" style={{backgroundColor: color2 }}>
            <li><div><HexColorPicker id ="hex" color={color2} onChange={setColor2}></HexColorPicker><HexColorInput id="colorIn" placeholder= {color2}></HexColorInput></div></li>
            </ul>
            </button>
            </li>
            <li>
            <button>Right Glove
            <ul class="sub-menu1" style={{backgroundColor: color3 }}>
            <li><div><HexColorPicker id ="hex" color={color3} onChange={setColor3}></HexColorPicker><HexColorInput id="colorIn" placeholder= {color3}></HexColorInput></div></li>
            </ul>
            </button>
            </li>
            <li>
            <button>Left Glove
            <ul class="sub-menu1" style={{backgroundColor: color4 }}>
            <li><div><HexColorPicker id ="hex" color={color4} onChange={setColor4}></HexColorPicker><HexColorInput id="colorIn" placeholder= {color4}></HexColorInput></div></li>
            </ul>
            </button>
            </li>
            <li>
            <button>Legwear
            <ul class="sub-menu1" style={{backgroundColor: color5 }}>
            <li><div><HexColorPicker id ="hex" color={color5} onChange={setColor5}></HexColorPicker><HexColorInput id="colorIn" placeholder= {color5}></HexColorInput></div></li>
            </ul>
            </button>
            </li>
            <li>
            <button>Boots
            <ul class="sub-menu1" style={{backgroundColor: color6 }}>
            <li><div><HexColorPicker id ="hex" color={color6} onChange={setColor6}></HexColorPicker><HexColorInput id="colorIn" placeholder= {color6}></HexColorInput></div></li>
            </ul>
            </button>
            </li>
            </ul>
            </nav>
            </div>
    </section>
	<script src="canvas.js"></script>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <Footer></Footer>
    </>
  ) }