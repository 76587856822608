import React from 'react';
function reveal() {
  var reveals = document.querySelectorAll(".reveal");
  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
window.addEventListener("scroll", reveal);

function Home(){
  return (
    <>
	<div id="bottom">
    <div id = "footer">
      <p id = "footerContent">Email: <a id= "spec" href="mailto: tristen@solpilot.io"  target="_blank">tristen@solpilot.io</a></p>
      <p id = "footerContent">Discord: <a id= "spec" href="https://discord.gg/SPBxVqdAsT"  target="_blank">SolPilot.io</a></p>
	  <p id = "footerContent">Twitter: <a id= "spec" href="https://twitter.com/SolPilot1"  target="_blank">SolPilot1</a></p>
	  <p id = "footerContent">Instagram: <a id= "spec" href="https://instagram.com/SolPilot.io"  target="_blank">Solpilot.io</a></p>
	  <p id = "footerClaim">SolPilot Inc. ©️ 2022</p>
    </div>
	</div>
    </>
  )
}

export default Home;