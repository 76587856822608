import React from 'react';
import Footer from "./Footer";
function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

function displayMenu(){
    const temp = document.getElementById("mi");
    temp.style.display = "block";
    document.getElementById("hamMenu2").style.display="block";
    document.getElementById("hamMenu").style.display="none";
   }
   function hideMenu(){
     const temp = document.getElementById("mi");
     temp.style.display = "none";
     document.getElementById("hamMenu2").style.display="none";
     document.getElementById("hamMenu").style.display="block";

    }

function Lore(){
  return (
    <>
    <body>
    <div id ='stars4'></div>
      <div id='stars'></div>
    <div id='stars2'></div>
    <div id='stars3'></div>
    <div id="top">
      <ul class = "nav">
        <li><a id ="href" href="/">Sol Pilot .io</a></li>
        <li><a id ="href" href="#footer">Contact</a></li>
      </ul>
      <button id="hamMenu" onClick={()=>displayMenu()}>
        <div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
      <button id="hamMenu2" onClick={()=>hideMenu()}><div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
      <ul id ="mi">
          <li><a id ="tab" href="/">Home</a></li>         
          <li><a id ="tab" href="/gallery">Gallery</a></li>
          <li><a id ="tab" href="/whitepaper">White Paper</a></li> 
		  <li> <a  id = "signUp" href="/register">Register</a></li>
      </ul>
    </div>
  <br></br><br/>
  <h1 id ="rT">Sol System Lore</h1>
  <br></br><br></br><br></br><br></br>
  <br></br><br id="lore"></br><br></br><br></br><br></br><br></br>
			 <h1 id = "lore">[Time: 03.14.2166]</h1>
			 <h1 id = "lore">[Location: Sol Star System, Planet Earth]</h1>
			  <div class="container reveal">
				<br></br><br/>
			 <h1 id = "lore2">Humanity's population has boomed to over 23 billion, in the Sol System alone.</h1>
			 </div>
			 <br></br><br/>
			 <div class="container reveal">
			 <h1 id = "lore2">Confederation mismanagement of resources has lead to planet-wide black-outs, famine, war, and anarchy.</h1>
			 </div>
			 <br></br><br/>
			 <div class="container reveal">
			 <h1 id = "lore2">A mass exodus of surface dwellers has begun, those souls brave enough to venture into the unknown, seeking a kinder world.</h1>
			 </div>
			<br></br><br/>
			<div class="container reveal">
			 <h1 id = "lore2">However, the Interplanatary Confederation of States is desperate to maintain its power and populace.</h1>
			 </div>
			 <br></br><br/>
			 <div class="container reveal">
			 <h1 id = "lore2">I.C.S. offers bounties on any ship caught in Sol System space.</h1>
			 </div>
			 <br></br><br/>
			 <div class="container reveal">
			 <h1 id = "lore2">Rewards are a minimum of 0.05 $SOL.</h1>
			 </div><br></br><br/>
			 <div class="container reveal">
			 <h1 id = "lore2">Your mission is to destroy any and all space-fairing vessels, profiting from their destruction.</h1>
			 </div>
			<br></br><br/>
			<div class="container reveal">
			<h1 id = "lore2">But be wary, for you too are in the no-fly zone of I.C.S. jurisdiction.</h1>
			</div>
			<br></br><br/>
            <h1 id = "lore" class="typing">Godspeed Pilot...</h1>
			<br></br><br/>
	<Footer></Footer>
    </body>
  </>)};
  export default Lore;