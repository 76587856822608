import React, {Component} from 'react';
import dis from "./asset/discord.png";
import twitter from "./asset/twitter.png";
import insta from "./asset/insta.png";
import Footer from "./Footer";
import { useRef, useState, useEffect, useContext } from 'react';
import axios from './api/axios';
const LOGIN_URL = '/auth';
function reveal() {
  var reveals = document.querySelectorAll(".reveal");
  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;
    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}
var link ='Connect';
function delay(time) {
  return new Promise(resolve => setTimeout(resolve, time));
}

window.addEventListener("scroll", reveal);
function Home(){
	const [wallet, setWallet] = useState('');
	const [username, setUsername] = useState('');
	const [link, setLink] = useState('Connect');
	 const handleSubmit = async (e) => {
        e.preventDefault();
		window.solana.connect();
		await window.solana.on('connect',() => setWallet(window.solana.publicKey.toString()), connection());
		setLink("Log In");
		}
    const connection = async () => {
      const response = await axios.post("https://spserversp.herokuapp.com/auth",
                   JSON.stringify({wallet}),
                  JSON.stringify({
                      headers: { 'Content-Type': 'text/plain'},
                      withCredentials: true
                  })
                  );
          console.log(response);
        await setUsername(response.data.toString());
        console.log(username);
        setLink("Connected");
    }

    function displayMenu(){
     const temp = document.getElementById("mi");
     temp.style.display = "block";
     document.getElementById("hamMenu2").style.display="block";
     document.getElementById("hamMenu").style.display="none";
    }
    function hideMenu(){
      const temp = document.getElementById("mi");
      temp.style.display = "none";
      document.getElementById("hamMenu2").style.display="none";
      document.getElementById("hamMenu").style.display="block";
 
     }

  return (
    <>
      <div id="top">	
        <ul class = "nav" id="bav"> <li> <a id = "uName">{wallet? username: "🌕"}</a></li>
          <li><a id ="href" href="#scrolli">Roadmap</a></li>         
          <li><a id ="href" href="/gallery">Gallery</a></li>
          <li><a id ="href" href="/lore">Lore</a></li> 
          <li><a id ="href" href="/whitepaper">White Paper</a></li>
		  <li><button id ="homeButton" onClick={handleSubmit}>{link} </button></li>
		  <li> <a  id = "signUp" href="/register">Register</a></li>
		  </ul>
      <button id="hamMenu" onClick={()=>displayMenu()}>
        <div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
      <button id="hamMenu2" onClick={()=>hideMenu()}><div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
  <div id="z">
		  <a id ="uName"> {wallet ? wallet[0] + wallet[1] + wallet[3] + wallet[4] +'...' + wallet[wallet.length -3] + wallet[wallet.length -2] + wallet[wallet.length -1] : "🌕"}
			</a>
      </div>
      <ul id ="mi">
          <li><a id ="tab" href="#scrolli">Roadmap</a></li>         
          <li><a id ="tab" href="/gallery">Gallery</a></li>
          <li><a id ="tab" href="/lore">Lore</a></li> 
          <li><a id ="tab" href="/whitepaper">White Paper</a></li>
		  <li><button id ="homeButton" onClick={handleSubmit}>{link} </button></li>
		  <li> <a  id = "signUp" href="/register">Register</a></li>
      </ul>
      
      </div>
      <div id ='stars4'></div>
      <div id='stars'></div>
    <div id='stars2'></div>
    <div id='stars3'></div>
      <div id ="title" class="patterns">
        <svg width="100%" height="100%">          
          <rect x="0" y="0" width="100%" height="100%" fill="url(#empty)"> </rect>
       <text x="50%" y="60%"  text-anchor="middle"  >
         SolPilot
       </text>
       </svg>
      </div>
                   <h1><br></br>Shoot for the moon<span id="white">,</span> godspeed<span id="white">.</span></h1>
                    <div id="logBox" class="bb"><a id="linkIn" href="/game" target="_blank">Play Demo</a></div>
            <br></br><br></br><br></br><br></br>
    <div id="about">
    <div class="text-container">
      <div class="text-box">
	   <div class = "container" id ="disContain">

				<a id ="dis" href="https://twitter.com/SolPilot1"><img id ="dis"src={twitter}></img></a>	  
				<br></br>
				</div>
      </div>
	  <div class="text-box">
	   <div class = "container" id ="disContain">

				<a id ="dis" href="https://instagram.com/SolPilot.io"><img id ="dis"src={insta}></img></a> 
				<br></br>
				</div>
      </div>
	  <div class="text-box">
        <div class = "container" id ="disContain">

				<a id ="dis" href="https://discord.gg/SPBxVqdAsT"><img id ="dis"src={dis}></img></a>	  
				<br></br>
				</div>
      </div>
      </div>
    </div>
            <section id ="sectionRM">
  <div class="container reveal">
  <div id = "scrolli"></div>
    <h1 id = "rT">Plan of Action</h1>
    <h1 id = "rT2">🚀🔥🌙</h1>

    <br></br><br></br><br></br><br></br>
    </div>
    <div class="container reveal">
    <div>

    
    <h3 id ="rSub">Phase α</h3>

    <div class="text-container">
      <div class="text-box-complete">
        <h3 >Website Initialization 👌</h3>
        <p>
            Website Created and Functional.<br></br><br></br> [ February 2022 ]
        </p>
		
      </div>
      <div class="text-box">
        <h3>NFT Design 📋</h3>
        <p>
          You must have a qualified pilot to fly a spaceship. We will design Pilot NFTs and a pilot creation tool that will allow you to claim a pilot and account for solpilot.io. <br></br><br></br> [ March 2022 ]
        </p>
      </div>
      <div class="text-box">
        <h3>Pilot Minting 📋</h3>
        <p>
          Use the pilot creation tool to design your pilot. Or use a stylish NFT you already own as the profile of your solpilot. <br></br><br></br> [ March 2022 ]
        </p>
      </div>
      </div>
    </div>
  </div>
</section>   
<section>
  <div class="container reveal">
    <div>
  <ul id = "center">
    <h3 id ="rSub">Stage β - Rocket Fueling</h3>
    </ul>
    <div class="text-container">
      <div class="text-box">
        <h3 >Game Development 📋</h3>
        <p>
          A universe must exist for your mercenary combat. Development of the implementation of a 3-dimensional spaceship-combat dog-fight game. Winners will claim the value of their opponents in a multiplayer zero-sum fight to the death. <br></br><br></br> [ May 2022 ]
        </p>
      </div>
      <div class="text-box">
        <h3>Web Implementation 📋</h3>
        <p>
          Playable in the web browser, solpilot.io will be accessible to all skilled pilots. <br></br><br></br> [ June 2022 ]
        </p>
      </div>
      <div class="text-box">
        <h3>Blockchain Integration 📋</h3>
        <p>
          Get paid to play. Solana Blockchain Smart contracts will be deployed to deliver to you the profits of your skill. <br></br><br></br> [ June 2022 ]
        </p>
      </div>
    </div>
  </div>
  </div>
</section>
<section id="lift"> 
  <div class="container reveal">
  <ul id = "center">
    <h3 id ="rSub">Stage γ - Lift-off</h3>
    </ul>
    <div class="text-container">
      <div id='center'>
      <div class="text-box">
        <h3 >🚀 SolPilot.io Launch 📋 </h3>
        <p>
          Get ready to rumble in this strategic multiplayer play to earn game. <br></br><br></br> [ July 2022 ]
        </p>
      </div> 
      </div>  
      </div>
      </div>
	</section> 
	<Footer></Footer>
    </>
  )
}

export default Home;