import { useRef, useState, useEffect } from "react";
import axios from './api/axios';
import Footer from "./Footer"
const USER_REGEX = /^[A-z][A-z0-9-_]{1,32}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const WALLET_REGEX = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
const REGISTER_URL = '/register';

function displayMenu(){
  const temp = document.getElementById("mi");
  temp.style.display = "block";
  document.getElementById("hamMenu2").style.display="block";
  document.getElementById("hamMenu").style.display="none";
 }
 function hideMenu(){
   const temp = document.getElementById("mi");
   temp.style.display = "none";
   document.getElementById("hamMenu2").style.display="none";
   document.getElementById("hamMenu").style.display="block";

  }
const Register = () => {
  const [linkWallet, setLinkWallet] = useState("Link Phantom Wallet");
  const [wallet, setWallet] = useState();
  const getAccount= async() => {
     window.solana.connect();
  	await window.solana.on('connect',() => setWallet(window.solana.publicKey.toString()), console.log(wallet), setLinkWallet("Connection successful"));
}
    const userRef = useRef();
    const errRef = useRef();
    const [user, setUser] = useState('');
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);
	  const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        userRef.current.focus();
    }, [])
    useEffect(() => {
        setValidName(USER_REGEX.test(user));
    }, [user])
	useEffect(() => {
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])
    useEffect(() => {
        setErrMsg('');
    }, [user, email])
    const handleSubmit = async (e) => {
        e.preventDefault();
        // if button enabled with JS hack
        const v1 = USER_REGEX.test(user);
        const v2 = EMAIL_REGEX.test(email);
        if (!v1) {
            setErrMsg("Username must begin with a letter. Letters, numbers, underscores, and hyphens are allowed.");
            return;
        }
		if (!v2) {
            setErrMsg("Invalid Email");
            return;
        }
        const reg = user + "," + email + "," + wallet;
        const rex = JSON.stringify(reg);
        try {
            const response = await axios.post('https://spserversp.herokuapp.com/register',
                JSON.stringify(reg),
                JSON.stringify({
                  headers: { 'Content-Type': 'application/json'},
                    withCredentials: true
                })
              );
            console.log(response?.data); //remove
            console.log(response?.accessToken);
            console.log(JSON.stringify(response)) //remove
            setSuccess(true);
            setUser('');
            setEmail('');
        } catch (err) {
            if (!err?.response) {
                setErrMsg('Server not responding. Please try again.');
            } else if (err.response?.status === 409) {
                setErrMsg('Username Taken');
			}
			  else if (err.response?.status === 410) {
                setErrMsg('Email already in use');
            }
			  else if (err.response?.status === 411) {
                setErrMsg('Wallet already in use');
            }
             else {
                setErrMsg('Error Z')
				console.log(err)
            }
            errRef.current.focus();
        }
    }
    return (
      
        <>
			<div id='stars'></div>
    <div id='stars2'></div>
    <div id='stars3'></div>
	<div id="top">
      <ul class = "nav">
        <li><a id ="href" href="/">Sol Pilot .io</a></li>
        <li><a id ="href" href="#footer">Contact</a></li>
      </ul>
      <button id="hamMenu" onClick={()=>displayMenu()}>
        <div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
      <button id="hamMenu2" onClick={()=>hideMenu()}><div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
      <ul id ="mi">
          <li><a id ="tab" href="/">Home</a></li>         
          <li><a id ="tab" href="/gallery">Gallery</a></li>
          <li><a id ="tab" href="/lore">Lore</a></li> 
		  <li> <a  id = "signUp" href="/whitepaper">White Paper</a></li>
      </ul>
    </div>
	<br/>
	<br/>
	<br/>
	<h1 id ="rT">Pilot Registration</h1>
	<br/>
	    <h3 id ="cpSub">Phantom Wallet add-on <a id ="green" href ="https://phantom.app/" target="_blank" rel="noopener">here</a></h3>
            {success ? (
                <section>
                    <h1 id ="response">Success!</h1>
					<br/>
                    <h1 id ="response">You have been registered with SolPilot.</h1>
					<br/>
                    <h1 id ="response">Godspeed pilot.</h1>
                    <p>
                        <a href="/">Home</a>  
                    </p>
                </section>
            ) : (
                <div>            
						<form  autoComplete='off' class='form' method="POST" action="#">
							<div class ="sub">
						  <div class='control'>
						  </div>
						  <div class='control block-cube block-input'>
							<input
							placeholder="Username"
                            type="text"
                            id="username"
                            ref={userRef}
                            autoComplete="off"
                            onChange={(e) => setUser(e.target.value)}
                            value={user}
                            required
                            aria-invalid={validName ? "false" : "true"}
                            aria-describedby="uidnote"
                            onFocus={() => setUserFocus(true)}
                            onBlur={() => setUserFocus(false)}
                        />
							<div class='bg-top'>
							  <div class='bg-inner'></div>
							</div>
							<div class='bg-right'>
							  <div class='bg-inner'></div>
							</div>
							<div class='bg'>
							  <div class='bg-inner'></div>
							</div>
						  </div>
							<br/>
						  <div class='control block-cube block-input'>
							<input
							placeholder ="Email"
                            type="text"
                            id="password"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            aria-describedby="pwdnote"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                        />
							<div class='bg-top'>
							  <div class='bg-inner'></div>
							</div>
							<div class='bg-right'>
							  <div class='bg-inner'></div>
							</div>
							<div class='bg'>
							  <div class='bg-inner'></div>
							</div>
						  </div>
						  <br/>
							<button2 tabindex="0" class='btn block-cube block-cube-hover' type='button' onClick={getAccount}>
							<div class='bg-top'>
							  <div class='bg-inner'></div>
							</div>
							<div class='bg-right'>
							  <div class='bg-inner'></div>
							</div>
							<div class='bg'>
							  <div class='bg-inner'></div>
							</div>
							<div class='text'>
							  {linkWallet}
							</div>
						  </button2>
						  <br/><br/><br/>
						  <button2 tabindex= "0" onClick={handleSubmit} disabled={!validName || !validEmail  ? true : false} 
						  class='btn block-cube block-cube-hover' type='button'>
							<div class='bg-top'>
							  <div class='bg-inner'></div>
							</div>
							<div class='bg-right'>
							  <div class='bg-inner'></div>
							</div>
							<div class='bg'>
							  <div class='bg-inner'></div>
							</div>
							<div class='text'>
							  Confirm Pilot Registration
							</div>
						  </button2>
						  </div>
						</form>
						                    <p id = "error" ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
                </div>
            )}
			<Footer/>
        </>
    )
}

export default Register