import React from 'react';
import title from"./asset/Title.png";
import pilots from"./asset/P.png";
import ships from"./asset/second.png";
import shipfactories from"./asset/third.png";
import gamemechanics from"./asset/GM.png";
import Footer from "./Footer";
import {useState} from 'react';

const Title = 
<>
<div id="detailsContainer">
    <div id ="titleContainer">
        <h1> <i>SolPilot metagame</i></h1>
    <p>A metagame is a game within the metaverse.</p>
    
    <div id ="detailsContainer"><h2>Our Vision</h2>
    <p>SolPilot shoots for the highest mark, with perfectionist quality and earnest loyalty.</p>
    <p>We imagine a metaverse teeming with wealth, life, and soul. Creation is our calling.</p>
    <p>The world craves profitable and unique metaprojects, and this novel metagame is our humble contribution.</p>
    </div>
    <div id ="detailsContainer"><h2>Our Passion</h2>
    <p>Cryptocurrency is prejudicially and erroneously associated with scams and illicit activities.</p>
    <p>It truthfully is an unprecedented opportunity for financial freedom from corrupted private and public institutions.</p>
    <p>The potentiality of cryptocurrency is nigh unlimited, it must simply be realized and seized by the people.</p>
    </div>
    <div id ="detailsContainer"><h2>Our Conviction</h2>
    <p>SolPilot is a community dedicated to birthing creative uses of cryptocurrency towards the aim of<br></br> actualizing veritable financial freedom.</p>
    <p>For too long have we laboured under the regimes of industrial, technocratic, and monopolistic tryants.</p>
    <p>It is freedom we seek, and freedom we shall bring.</p>
    </div>
    <p>Godspeed.</p></div>
</div>
<br></br>
</>
const Pilots =
<>
<div id="detailsContainer">
    <div id ="titleContainer">
        <h1> <i>Pilots</i></h1>
    <p>To fly a sidereal class battleship, one must have a certified pilot.</p>
    
    <div id ="detailsContainer"><h2>NFIs</h2>
    <p>A Pilot is an NFI (Non-Fungible Item) as opposed to an NFT, as it is non-transferable between wallets.</p>
    <p>The reason for this is that the value of a Pilot comes not from trading, but from the access it provides <br></br>to the SolPilot game and it's profitable crypto mechanics.</p>
    <p>Twenty-one percent of the proceeds from Pilots are forwarded to players and Ship Factory holders.</p>
    </div>
    <div id ="detailsContainer"><h2>Creation</h2>
    <p>Pilots can be created with the Pilot Creation Tool, which allows for selection of Pilot designs and hues.</p>
    <p>One may design any variegated aesthetics one desires, and this pilot is shown within the game as your avatar.<br></br>Alternatedly, one may choose to use another NFT/NFI from within your wallet to use as an avatar.</p>
    <p>Owning a Pilot is necessary to play SolPilot, and is akin to owning a game diskette, or copy of the game.<br></br>(Pilots also get whitelisted on the official SolPilot Minecraft Server. See the <a id="cyan" href="https://discord.gg/SPBxVqdAsT">Discord</a> for more information!</p>
    </div>
    <div id ="detailsContainer"><h2>Specials</h2>
    <p>The first 100 pilots created will have an alpha background and crown.</p>
    <p>One in every 100 Pilots minted will have a unique rare background.</p>
    <p>One in every 1000 Pilots will have a unique pet. (These are all cosmetic).</p>
    </div>
    </div>
</div>
</>
const Ships =
<>
<div id="detailsContainer">
    <div id ="titleContainer">
        <h1> <i>Ships</i></h1>
    <p>How are you going to shoot anyone without a ship?</p>
    
    <div id ="detailsContainer"><h2>NFTs</h2>
    <p>A Ship is an NFT and may be burned for its full price in SOL, or sold to another. (Minus gas fees, of course).</p>
    <p>Destroying another players ship grants you a ship in your wallet, which can be used or burned for Solana.</p>
    <p>The smart contract that holds the value of these ships will only ever return value when a ship is burned, <br></br> thereby keeping all Ship profit with players.</p>
    </div>
    <div id ="detailsContainer"><h2>Aspects</h2>
    <p>Strategy in customizability is the nature of the Ship.</p>
    <p>Ships have 4 aspects with 5 point levels each, a primary weapon and a secondary weapon. All Ships have the same Gatling Secondary. <br></br>
    Aspects are Speed, Agility, Hit Points, and Secondary Gatling Fire rate, with 15 point to spend on them in total.</p>
    <p>Primary weapons may be selected at will, each with there own features, advantages, and disadvantages.<br></br>
    These are the: Laser Obilterator, Nuclear Torpedo, Gravity Gun, Singularity Cannon, Incindiary Torrent, and the Space-Time Manipulator.</p>
    </div>
    <div id ="detailsContainer"><h2>Aesthetics</h2>
    <p>Ships are generated with random aesthetics. These would be body designs, hull colorings, and exhaust patterns.</p>
    <p>One in every 100 Ships will be of the rare Tormentor class (which is purely cosmetic).</p>
    <p>One in every 1000 Ships will have a golden hull.</p>
    </div></div>
</div>
</>
const ShipFactories =
<>
<div id="detailsContainer">
    <div id ="titleContainer">
        <h1> <i>Ship Factories</i></h1>
    <p>They simply makes money.</p>
    
    <div id ="detailsContainer"><h2>NFTs</h2>
    <p>A Ship Factory is an NFT. It may be resold, with a 3.5% royalty to SolPilot, and may not be burned.</p>
    <p>Proceeds generated by Ship Factory sales will go directly toward the development of SolPilot <br></br>(Game development is particularly onerous).</p>
    <p>Unlike Pilots and Ships, Ship Factories are of limited quantity, for only 49 will ever be minted.</p>
    </div>
    <div id ="detailsContainer"><h2>Returns</h2>
    <p>For every 75 Pilots minted, 43 ships will be minted by Ship Factories. This corresponds to sixteen percent of Pilot proceeds.</p>
    <p>Ship factories produce Ships at 4 tier levels, selected randomly. There are 4 of Tier 4, 10 of Tier 3, 15 of Tier 2, and 20 of Tier 1. </p>
    <p>Individually, Tier 1 Ship Factories produce 1 Ship for every 150 Pilots minted, Tier 2 produces 2, Tier 3 produces 3, Tier 4, 4.<br></br>
    In total, for every 150 Pilots minted, 96 Ships are minted and distributed amongst all Ship Factory holders.</p>
    </div>
    <div id ="detailsContainer"><h2>Offerings</h2>
    <p>The first 10 Ship factories will be minted for 5 Sol. After this, the mint value will correspond to the formula: <br></br>
     5 + Number of Ship Factory Holders / Pilots Minted </p>
    <p>A single Tier 4 Ship Factory bought at 5 Sol would produce its cost in Ships after 938 Pilots are minted.<br></br>
    Ships can be burned for their full value of 0.05 SOL a pop.</p>
    <p>Each Tier has its own design, and one Tier 4 Ship Factory will have a unique opulent design.</p>
    </div></div>
</div>
</>
const GameMechanics =
<>
<div id="detailsContainer">
    <div id ="titleContainer">
        <h1> <i>Game Mechanics</i></h1>
    <p>Turning your gaming session into cryptocurrency.</p>
    
    <div id ="detailsContainer"><h2>Architecture</h2>
    <p>SolPilot is a multiplayer, three dimensional, space combat dogfight, metagame set in the Sol System in the year 2166.</p>
    <p>The game is developed using the Godot game engine and is hosted on our dedicated servers.</p>
    <p>The core game mechanics are manuevering, shooting, and getting $SOL crypto.</p>
    </div>
    <div id ="detailsContainer"><h2>Sol Bounties and Sol Airdrops</h2>
    <p>For every opponent's Ship destroyed, the player receives the Ship, which can be flown or burned for 0.05 SOL.</p>
    <p>Five percent of the value of Pilots sold will be dispersed within the game as suspended collectable coins, each worth 0.01 SOL.</p>
    <p>By employing your gaming prowess, profiting SOL is the objective of playing SolPilot.</p>
    </div>
    <div id ="detailsContainer"><h2>Gameplay and Skill Balancing</h2>
    <p>You use your primary and secondary weapons to destroy opponent's ship, and you can use your tractor beam to collect debris to repair your own.</p>
    <p>There are 11 levels, each with their own unique layout and aesthetics. On destroying a ship, you advance to the next level, allowing you a reprieve to collect your bounty.</p>
    <p>Skill is naturally balanced between levels, so Pilots with many kills cannot lurk in the spawn area to prey on newbs.</p>
    </div>   <p>    Cya in the Sol System. Godspeed, Pilot.
</p>
</div>
</div>
</>
    function displayMenu(){
        const temp = document.getElementById("mi");
        temp.style.display = "block";
        document.getElementById("hamMenu2").style.display="block";
        document.getElementById("hamMenu").style.display="none";
       }
       function hideMenu(){
         const temp = document.getElementById("mi");
         temp.style.display = "none";
         document.getElementById("hamMenu2").style.display="none";
         document.getElementById("hamMenu").style.display="block";
    
        }
   
function WhitePaper(){
    const SlideShown = [title, pilots, ships, shipfactories, gamemechanics]
    const DetailsShown = [Title, Pilots, Ships, ShipFactories, GameMechanics]
    const [int, setInt, getInt] = useState(0);
    function nextSlide(){
        setInt(int+1);
        setDisplay(int +1);
    }
    function previousSlide(){
        setInt(int-1); 
        setDisplay(int -1);    
    }
    function loadDetails(){
            const temp = document.getElementById("details");
            temp.style.display = "flex";
    }
    
      function setDisplay(integer) {
        if(integer === 0){
            const temp = document.getElementById("b2");
            temp.style.opacity = "0%"
            temp.style.cursor = "default";
            temp.style.pointerEvents = "none";
        }
        else if(integer === SlideShown.length-1){
            const temp = document.getElementById("b22");
            temp.style.opacity = "0%"
            temp.style.cursor = "default";
            temp.style.pointerEvents = "none";
        }
        else{
            const temp1 = document.getElementById("b2");
            temp1.style.opacity = "100%"
            temp1.style.cursor = "crosshair";
            temp1.style.pointerEvents = "";
            const temp2 = document.getElementById("b22");
            temp2.style.opacity = "100%"
            temp2.style.cursor = "crosshair";
            temp2.style.pointerEvents = "";
        }
        const temp = document.getElementById("details");
            temp.style.display = "none";
        }
        document.addEventListener("DOMContentLoaded",  function(event) { setDisplay(int)});
    return (
      <>
      <link rel="stylesheet"  href="src\syles.css"></link>
      <div id="top">
        <ul class = "nav" >
          <li><a id ="href" href="/">Sol Pilot .io</a></li>
          <li><a id ="href" href="#footer">Contact</a></li>
        </ul>
        <button id="hamMenu" onClick={()=>displayMenu()}>
        <div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
      <button id="hamMenu2" onClick={()=>hideMenu()}><div id = "ham"><span>_</span>
  <span>_</span>
  <span>_</span></div></button>
      <ul id ="mi">
          <li><a id ="tab" href="/">Home</a></li>         
          <li><a id ="tab" href="/gallery">Gallery</a></li>
          <li><a id ="tab" href="/lore">Lore</a></li> 
		  <li> <a  id = "signUp" href="/register">Register</a></li>
      </ul>
      </div>
      <img id ="whitePaper" src = {SlideShown[int]}></img>
      <div id ="b2Container">
      <button id ="b2" onClick={() => previousSlide()}>Previous</button>
      <button id ="b21" onClick={() => loadDetails()}><a href="#details">Details</a></button>
      <button id ="b22" onClick={() => nextSlide()}>Next</button>
      </div>
      <br/>
      <br/>
      <div id="detailsContainer"><h1 id="details">{DetailsShown[int]}</h1></div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <Footer></Footer>
    </>)};
    export default WhitePaper;